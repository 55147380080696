import React from "react";
import Header from "../components/atoms/header";
import ManualContainer from "../components/atoms/ManualContainer/ManualContainer";
import { Helmet } from "react-helmet";
function ConnectManual() {
  return (
    <>
      <Helmet>
        <title>Connect Manual</title>
        <link rel="canonical" href="https://masterwizr.com/connectmanual" />
      </Helmet>
      <Header />
      <ManualContainer
        image={
          "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1673455317/Manuals/connect12manual_xi992z.png"
        }
      />
    </>
  );
}
export default ConnectManual;
